import { useEffect } from 'react'

const IndexPage = () => {
  useEffect(() => {
    const locale = navigator.language || navigator.browserLanguage

    const language = locale.substring(0, 2)

    if (language === 'de') {
      window.location.replace(`/de`)
    } else {
      window.location.replace(`/en`)
    }
  }, [])

  return null
}

export default IndexPage
